<div class="modal-dialog boders">
  <div class="modal-content login-form">
    <div class="login-headers">
      <h1 class="login-header-text montserrat">{{labels.pcmcSmartSarathi}}</h1>
      <h4 class="login-header-text montserrat">{{labels.adminPortal}}</h4>
    </div>
    <hr />
    <div class="modal-body">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              formControlName="username"
              placeholder="Mobile"
              [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
            />
            <i class="fa fa-user"></i>
            <div
              *ngIf="submitted && f.username.errors"
              class="invalid-feedback"
            >
              Mobile number is required
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <input
              [type]="hide ? 'password' : 'text' " 
              class="form-control"
              formControlName="password"
              placeholder="Password"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
            />
            <i class="fa fa-eye" style="cursor: pointer;" (click)="togglePass()" *ngIf="hide"></i>
            <i class="fa fa-eye-slash" style="cursor: pointer;" (click)="togglePass()" *ngIf="!hide"></i>
            <div
              *ngIf="submitted && f.password.errors"
              class="invalid-feedback"
            >
              Password is required
            </div>
          </div>
        </div>
        <div class="form-group text-center">
          <button type="submit" class="btn btn-primary btn-lg btns">
            {{labels.login}}
          </button>
        </div>
      </form>
      <div class="text-center">
        <select id="changelanguage" class="btnSS" (change)="languageChange($event.target.value)" [(ngModel)]="defaltLanguage">
          <option value="en">English</option>
          <option value="mar">मराठी</option>
        </select>
      </div>
    </div>
  </div>
</div>
