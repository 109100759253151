import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { LoginService } from "src/app/login.service";
import * as CryptoJS from "crypto-js";
import { HttpService } from "../services/http.service";
@Component({
  selector: "app-signin",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public unsubscribe: Subject<any> = new Subject();
  logindata: any = [];
  hide: boolean = true;
  loginForm: FormGroup;
  submitted = false;
  whichPage = "signin";
  currentLanguage: any;
  returnUrl: string;
  currentLanguageIsEnglish =
    sessionStorage["currentLanguage"] == "en" ? true : false;
  defaltLanguage: any = "en";
  labels:any = 'en';

  constructor(
    private router: Router,
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private api: LoginService
  ) {
    // redirect to home if already logged in
    if (this.loginService.currentUserValue) {
      this.router.navigate(["/"]);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      deviceId: "",
      device_token: "",
      device_type: "Web",
      username: ["", Validators.required],
      password: ["", Validators.required],
    });
    this.getLabels();
    if(!localStorage.getItem('labelLanguage'))
      localStorage.setItem('labelLanguage', 'en');
  }

  get f() {
    return this.loginForm.controls;
  }

  // for Increaption
  set(keys, value) {
    var key = CryptoJS.enc.Hex.parse(keys);
    var iv = CryptoJS.enc.Hex.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value.toString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return encrypted.toString();
  }

  // for decreaption
  get(keys, value) {
    var key = CryptoJS.enc.Hex.parse(keys);
    var iv = CryptoJS.enc.Hex.parse(keys);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Hex);
  }

  getLabels(){
    this.api.getLogin().subscribe((data: any) => {
      if (data) {
        // console.log("Labels",data.result)
        let row = {
          "admin":data.result.admin
        }
        // console.log("yvadda",row)
        localStorage.setItem('allLabels', JSON.stringify(row));
        this.labels = row;
        this.languageChange('en');
      } else {
        console.log("error");
      }
    });
  }

  onSubmit() {
    var encryptedPassword = this.set(
      "0123456789abcdef0123456789abcdef",
      this.loginForm.value.password
    );
    this.submitted = true;
    if (this.submitted && this.loginForm.status) {
      const body = {
        deviceId: "",
        device_token: "",
        device_type: "Web",
        mobile: "+91" + this.loginForm.value.username,
        password: encryptedPassword,
      };
      this.loginService.login(body).subscribe((data) => {
        if (data) {
          if(data.code == 200){
            localStorage.setItem("currentUser", JSON.stringify(data));
            this.router.navigate(["/user/citizen-list"]);
          }else {
            alert(data.message);
          }
        }
      });
    }
  }

  setCurrentLanguageAndLabels() {
    if (sessionStorage["currentLanguage"] == "en") {
      this.currentLanguageIsEnglish = true;
    } else if (sessionStorage["currentLanguage"] == "mar") {
      this.currentLanguageIsEnglish = false;
    } else {
      sessionStorage.setItem("currentLanguage", "mar");
      this.currentLanguageIsEnglish = true;
    }
  }

  languageChange(language){
    localStorage.setItem('labelLanguage', language);
    this.labels = JSON.parse(localStorage.getItem("allLabels"))!.admin!.login[language];
    
    localStorage.setItem('labelLanguage', language);
  }

  togglePass(){
    let val = this.hide;
    this.hide = !val;
    // console.log(this.hide)
  }
}
