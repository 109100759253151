<!-- <div class="h-100 overflow-y-auto">
    <div>
        <app-header></app-header>
    </div>
    <div class="d-flex w-100 flex-grow-1 main-container">
        <div class="">
            <app-sidebar></app-sidebar>
        </div>

        <div class="mt-4 p-4 flex-grow-1">
            <router-outlet></router-outlet>
        </div>

    </div>
</div> -->

<div class="h-100">
  <div class="header-box">
    <app-header></app-header>
  </div>
  <div class="container pl-0 pr-0">
    <!-- <div class="container"> -->
    <!-- <div class="row"> -->
    <div class="two-seprator">
      <!-- <div class="d-flex two-seprator"> -->
      <div class="sidebar-box">
        <app-sidebar></app-sidebar>
      </div>

      <div class="content-box mt-5 pt-4">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>