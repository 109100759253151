<nav class="navbar navbar-inverse">
  <div class="container-fluid">
    <div class="row w-100">
      <div class="col-sm-12">
        <div class="navbar-header">

          <div class="logo-box">
            <a class="navbar-brand montserrat" routerLink="/user/citizen-list">
              <img src="../assets/images/pmc-care-logo.jpg" class="logo-img" />
            </a>
          </div>

          <div class="right-box d-flex pt-2">
            <div ng-show="userObj.gender == 'F'">
              <img src="{{currentUser?.result?.profilePhoto}}" class="logo-FM" />
            </div>

            <div class="text-box ml-3">
              <span>
                {{currentUser?.result?.firstName}} {{currentUser?.result?.lastName}}
              </span><br>
              <span style="font-size: 85%;" *ngIf="currentUser?.result?.isGovernment">
                Department Head
              </span>
              <span style="font-size: 85%;" *ngIf="currentUser?.result?.isBenefitManager">
                Benefit Manager
              </span>
              <span style="font-size: 85%;" *ngIf="currentUser?.result?.isContentHead">
                Content Writer Head
              </span> 
              <span style="font-size: 85%;" *ngIf="currentUser?.result?.isOperationalHead && currentUser?.result?.isContentHead === false && currentUser?.result?.isBenefitManager === false">
                Operational Head
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>